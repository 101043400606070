import axios from "axios";
axios.defaults.withCredentials = true;

export const configs = {
  serverUrl: "https://sms.clcsl.edu.lk/serverclc",
  // serverUrl:'https://smstest.clcsl.edu.lk/smstest',
  // serverUrl: "http://localhost:3000",
  // frontEndUrl: "http://localhost:3001",
  frontEndUrl: "https://sms.clcsl.edu.lk",
  // frontEndUrl:'https://smstest.clcsl.edu.lk'
};

export const REQUEST_LOGIN_FAILED = "REQUEST_LOGIN_FAILED";
export const REQUEST_LOGIN_PENDING = "REQUEST_LOGIN_PENDING";
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS";
export const SET_SOURCE_INFO = "SET_SOURCE_INFO";
export const SET_STREAM = "SET_STREAM";
export const SET_YEAR = "SET_YEAR";
export const SET_SCHOOLING = "SET_SCHOOLING";
export const SET_MEDIUM = "SET_MEDIUM";

export const userVerification = () => {
  const x = localStorage.getItem("token");
  axios
    .post(`${configs.serverUrl}/verify-user`, { loginId: x })
    .then((res) => {
      // console.log(res.data)
      localStorage.setItem("isLoggedIn", "true");
    })
    .catch((err) => {
      localStorage.clear();
      window.location.replace("/#/login");
      // localStorage.setItem('isLoggedIn','false')
      // console.log('Verification failed at settings',err)
    });
};
