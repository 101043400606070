import { CContainer } from "@coreui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { configs } from "../../../constants";
import DataTableComponent from "../../components/dataTable/DataTableComponent";

function Profile(props) {
  const { studentInfo } = props;
  const [currentAttendance, setCurrentAttendance] = useState({});

  useEffect(() => {
    setCurrentAttendance(props.currentAttendance);
  }, [props.currentAttendance]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "I" || event.key === "i") {
        // event.preventDefault();
        // alert("In Selection");
      } else if (event.key === "O" || event.key === "o") {
        // event.preventDefault();
        // alert("Out Selection");
      } else if (event.key === "u" || event.key === "U") {
        event.preventDefault();
        updateStatus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      /*removes event listener on cleanup*/
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentAttendance]);

  const updateStatus = async () => {
    let status = currentAttendance.attendance_status === "in" ? "out" : "in";

    console.log(currentAttendance);

    const updatedResponse = await axios.post(
      `${configs.serverUrl}/manageAttendance`,
      {
        action: "updateSchoolAttendance",
        id: currentAttendance.id,
        status: status,
      }
    );

    setCurrentAttendance((prev) => {
      return { ...prev, attendance_status: status };
    });
  };

  return (
    <div className="fade-in">
      <CContainer>
        <div className="row text-left">
          <div className="col-sm-3">
            <img
              src={studentInfo.dp}
              width={200}
              alt="student profile"
              style={{ borderRadius: 10 }}
            />
          </div>
          <div className="col">
            <h3>Name: {studentInfo.full_name}</h3>
            <h4>Address: {studentInfo.perm_address}</h4>
            <h4>Telephone: {studentInfo.mobile}</h4>
            <h4>schooling: {studentInfo.schooling_type}</h4>
          </div>
        </div>
        <div className="row pt-3 text-left">
          <div className="col-sm-3">
            <h4>CURRENT RECORD</h4>
            <button
              className={
                currentAttendance.attendance_status === "in"
                  ? "btn btn-success"
                  : "btn btn-danger"
              }
              style={{ width: 100, height: 100 }}
              onClick={updateStatus}
            >
              <span style={{ fontSize: 30 }}>
                {currentAttendance.attendance_status}
              </span>
            </button>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col card pt-3">
            <h3>Attendance History</h3>
            <DataTableComponent
              dataList={
                studentInfo.schoolAttendanceRecords
                  ? studentInfo.schoolAttendanceRecords
                  : []
              }
              includedCols={["recorded_on", "last_updated_on", "status"]}
              loading={false}
              hideSearch={true}
            />
          </div>
          {/* <div className="col-sm-6 card pt-3">
            <h3>Payment History</h3>
            <DataTableComponent
              dataList={studentInfo.payments ? studentInfo.payments : []}
              loading={false}
              includedCols={[
                "to_be_paid",
                "p_description",
                "p_status",
                "date_from",
                "date_to",
              ]}
            />
          </div> */}
        </div>
      </CContainer>
    </div>
  );
}

export default Profile;
