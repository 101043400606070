import { createStore, applyMiddleware, combineReducers } from "redux";
import { setLogin, setApplicationInputs } from "./reducers";
import thunkMiddleware from "redux-thunk";
const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  changeState: changeState,
  setLogin: setLogin,
  setApplicationInputs: setApplicationInputs,
});
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
export default store;
