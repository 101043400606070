import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./scss/style.scss";
import "./App.css";
import SchoolAttendance from "./views/pages/schoolAttendance/SchoolAttendance";

const loading = (
  <div className="loader-container">
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const External = React.lazy(() => import("./views/pages/external/External"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Application = React.lazy(() =>
  import("./views/pages/application/StartPage")
);
const ApplicationMaster = React.lazy(() =>
  import("./views/pages/applicationNew/ApplicationMaster")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const schoolAttendance = React.lazy(() =>
  import("./views/pages/schoolAttendance/SchoolAttendance")
);

class App extends Component {
  state = {
    verified: false,
  };

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/external"
              name="External"
              render={(props) => <External {...props} />}
            />
            <Route
              exact
              path="/:applicationId/online-application"
              name="Online Application"
              render={(props) => <Application {...props} />}
            />
            {/* <Route
              exact
              path="/:applicationId/online-application"
              name="Online Application"
              render={(props) => <ApplicationMaster {...props} />}
            /> */}
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/schoolAttendance"
              name="School Attendance"
              render={(props) => <SchoolAttendance {...props} />}
            />
            {localStorage.getItem("isLoggedIn") === "true" ? (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            ) : (
              <Redirect to="/login" />
            )}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     user: state.setLogin.user,
//     isLoggedIn: state.setLogin.isLoggedIn
//   }
// }

export default App;
