import { CContainer, CForm, CInput } from "@coreui/react";
import logo from "../../../assets/logo/logo-large.png";
import React, {useRef, useState} from "react";
import Profile from "./Profile";
import axios from "axios";
import { configs } from "../../../constants";

function SchoolAttendance() {
  const [searchText, setSearchText] = useState("");
  const [studentInfo, setStudentInfo] = useState({ stName: "thanish" });
  const [showProfile, setShowProfile] = useState(false);
  const [timeInterval, setTimeInterval] = useState(60);
  const [currentAttendance, setCurrentAttendance] = useState({});
  const [showScreenSaver, setShowScreenSaver] = useState(true);
  const inputRef = useRef(null);

  const onChangeText = (event) => {
    if (event.target.value.length >= 2) {
      loadStudentInfo(event);
    }

    toggleScreenSaver();
  };

  const toggleScreenSaver = () => {
    setShowScreenSaver(false);

    setTimeout(() => {
      setShowScreenSaver(true);
    }, 10000)
  }

  const loadStudentInfo = async (event) => {
    let applicationId = event.target.value;

    const studentResponse = await axios.post(
      `${configs.serverUrl}/manageStudents`,
      {
        action: "getStudentsInfoById",
        applicationId: applicationId,
      }
    );

    if (studentResponse.data.data.length > 0) {
      setShowProfile(true);
      setStudentInfo(studentResponse.data.data[0]);

      const data = await axios.post(`${configs.serverUrl}/manageAttendance`, {
        action: "addSchoolAttendance",
        formData: {
          applicationId: applicationId,
        },
      });

      setCurrentAttendance(data.data.data[0]);
    } else {
      alert("Invalid Input");
    }

    hideStudentInfo();
  };

  const hideStudentInfo = () => {
    // setInterval(() => {
    //   if (timeInterval > 0) {
    //     setTimeInterval((interval) => interval - 1);
    //   }
    // }, 1000);
    setTimeout(() => {
      setShowProfile(false);
      toggleScreenSaver();
    }, 6000);
  };

  return (
    <div>
      <div className={showScreenSaver ? "screen-saver fade-in" : "display-none"} onClick={(event) => {inputRef.current.focus()}}>
        <video autoPlay loop muted poster="https://assets.codepen.io/6093409/river.jpg" width={'100%'}>
          <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4" />
        </video>
      </div>
      <CContainer className="text-center">
        {showProfile ? (
          <div>
            <div className="text-right">
              <h3>Closing in {timeInterval} seconds</h3>
              <button onClick={() => setShowProfile(false)} autoFocus>
                Go Back!
              </button>
            </div>
            <Profile
              studentInfo={studentInfo}
              currentAttendance={currentAttendance}
            />
          </div>
        ) : (
          <div className="fade-in">
            <img
              className="c-sidebar-brand-full"
              src={logo}
              alt="Logo"
              width="400"
            />
            <h1>Students Attendance Record Portal</h1>
            <h1 style={{ paddingTop: 100 }}>Scan Here!</h1>
            <input className='form-control' ref={inputRef} onChange={onChangeText} placeholder="Please type or scan" type="text" autoFocus />
            <div className="row"></div>
          </div>
        )}
      </CContainer>
    </div>
  );
}

export default SchoolAttendance;
