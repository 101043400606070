import { Delete, Edit } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect } from "react";

function TableRowItem(props) {
  const item = props.item;

  // useEffect(() => {
  //   console.log(Object.keys(item));
  // }, []);

  return (
    <tr
      className={"full-width table-container m-t-5 table-row " + props.classes}
      style={{ position: "relative" }}
    >
      {/* <div className="row"> */}
      <td className="col-sm-1 text-center">
        <input type={"checkbox"} />
      </td>
      {/* <div
          className="col d-flex align-items-between full-width"
          style={{ maxWidth: "100%", overflow: "overlay", flexWrap: "nowrap" }}
        > */}
      {Object.keys(item).map((itm, key) => {
        let itmString = item[itm];

        let dateString = moment(itmString).format("LLL");

        if (dateString !== "Invalid date" && typeof itmString !== "number") {
          itmString = dateString;
        }

        if (typeof itmString === "string" || typeof itmString === "number") {
          return (
            <td
              key={key}
              style={{
                backgroundColor:
                  itmString === "out"
                    ? "red"
                    : itmString === "in"
                    ? "green"
                    : "",
                color:
                  itmString === "out" || itmString === "in" ? "white" : "black",
              }}
            >
              {itmString}
            </td>
          );
        } else {
          return <td key={key}>--</td>;
        }
      })}
      {/* </div> */}
      <td
        style={{ position: "absolute", right: 0 }}
        className={props.hideActionPanel ? "d-none" : "table-row-hover-actions"}
      >
        {props.isHeader || props.isFooter ? (
          <div className="text-bold text-center"></div>
        ) : (
          <div className="text-right">
            {props.deleteItem ? (
              <button
                className="btn"
                onClick={() => props.deleteItem(props.masterItem)}
              >
                <Delete />
              </button>
            ) : (
              ""
            )}
            {props.editItem ? (
              <button
                className="btn"
                onClick={() => props.editItem(props.masterItem)}
              >
                <Edit />
              </button>
            ) : (
              ""
            )}
            {props.showMore ? (
              <button
                className="btn"
                onClick={() => props.showMore(props.masterItem)}
              >
                <i className="bi bi-arrow-right-square-fill"></i>
              </button>
            ) : (
              ""
            )}
          </div>
        )}
      </td>
      {/* </div> */}
    </tr>
  );
}

export default TableRowItem;
