import { CInput } from "@coreui/react";
import { SearchOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import TableRowItem from "./TableRowItem";

function DataTableComponent(props) {
  const [masterDataList, setMasterDataList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [dataList, setDataList] = useState([]);
  const [headerData, setHeaderData] = useState({});

  useEffect(() => {
    prepareData();
  }, [props.dataList]);

  useEffect(() => {
    setHeader();
  }, [dataList]);

  const prepareData = () => {
    if (props.includedCols) {
      const filteredData = props.dataList.map((data) => {
        let filtered = {};

        filtered.id = data.id;

        props.includedCols.map((col) => {
          filtered[col] = data[col];
        });

        return filtered;
      });

      setDataList(filteredData);
      setMasterDataList(filteredData);
    } else {
      setDataList(props.dataList);
      setMasterDataList(props.dataList);
    }
  };

  const fileterDataList = (event) => {
    setSearchKey(event.target.value);

    const searchResult = masterDataList.filter((item) => {
      let searchKeyString = props.searchKey ? props.searchKey : "label";

      if (item[searchKeyString]) {
        return item[searchKeyString]
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      } else {
        return true;
      }
    });

    setDataList(searchResult);
  };

  const setHeader = () => {
    if (dataList.length > 0) {
      let headerData = dataList[0];

      let headerObject = {};

      Object.keys(headerData).map((itm, key) => {
        if (
          typeof headerData[itm] === "string" ||
          typeof headerData[itm] === "number"
        ) {
          let camelCase = itm.replace(/([A-Z])/g, " $1");

          headerObject[itm] =
            camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
        }
      });

      setHeaderData(headerObject);
      console.log("header object", headerObject);
    }
  };

  return (
    <div style={{ maxWidth: "100%", overflow: "auto" }}>
      {props.hideSearch ? (
        ""
      ) : (
        <div className="d-flex mb-4 mt-1 m-1 justify-content-center">
          <CInput
            type="text"
            style={{ width: 300, marginRight: 10 }}
            value={searchKey}
            onChange={fileterDataList}
            id="searchInput"
          />
          <label htmlFor="searchInput">
            <SearchOutlined style={{ marginTop: 6 }} />
          </label>
        </div>
      )}

      <div className="table" style={{ minHeight: 300, position: "relative" }}>
        {props.loading ? (
          <div style={{ position: "absolute", zIndex: 100, left: 0, right: 0 }}>
            <LoadingSpinner />
          </div>
        ) : (
          <table width={"100%"} className="custom-fade">
            <thead>
              <TableRowItem
                item={headerData}
                isHeader={true}
                classes={"table-header"}
                hideActionPanel={true}
              />
            </thead>
            <tbody>
              {dataList
                ? dataList.map((item, id) => {
                    return (
                      <TableRowItem
                        key={id}
                        item={item}
                        masterItem={props.dataList.find((findItem) => {
                          return item.id === findItem.id;
                        })}
                        deleteItem={props.deleteAction}
                        editItem={props.editAction}
                        showMore={props.showMoreAction}
                        hideActionPanel={props.hideActionPanel}
                      />
                    );
                  })
                : "No Data Found"}

              {dataList ? (
                dataList.length === 0 && !props.loading ? (
                  <td
                    colSpan={Object.keys(headerData).length + 1}
                    className="text-center"
                  >
                    No Data Found
                  </td>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </tbody>
            <tfoot>
              <TableRowItem
                item={headerData}
                isFooter={true}
                classes="table-footer"
                hideActionPanel={true}
              />
            </tfoot>
          </table>
        )}
      </div>
      {/* <div className="full-width">
        <TableRowItem item={headerData} isHeader={true} />
      </div>
      <div className="table-container">
        {dataList.map((item, id) => {
          return (
            <TableRowItem
              key={id}
              item={item}
              deleteItem={props.deleteAction}
              editItem={props.editAction}
              showMore={props.showMoreAction}
            />
          );
        })}
      </div> */}
    </div>
  );
}

export default DataTableComponent;
