import {
  REQUEST_LOGIN_FAILED,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN_PENDING,
  SET_INPUT_FIELDS,
  SET_SOURCE_INFO,
} from "./constants.js";

const intialState = {
  isPending: false,
  error: "",
  user: [],
  isLoggedIn: false,
};

export const setLogin = (state = intialState, action = {}) => {
  switch (action.type) {
    case REQUEST_LOGIN_PENDING:
      return Object.assign({}, state, { isPending: true, isLoggedIn: false });
    case REQUEST_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        user: action.payload,
        isPending: false,
        isLoggedIn: true,
      });
    case REQUEST_LOGIN_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
        isLoggedIn: false,
      });
    default:
      return state;
  }
};

const initialStateApplication = {
  sourceInfo: "",
  stream: "",
  year: "",
  schooling: "",
  medium: "",
};

export const setApplicationInputs = (
  state = initialStateApplication,
  action = {}
) => {
  switch (action.type) {
    case SET_SOURCE_INFO:
      return { ...state, sourceInfo: action.payload };
    //   return Object.assign({}, state, { sourceInfo: action.payload });
    default:
      return state;
  }
};
